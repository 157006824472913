import React, { useState, useEffect } from 'react'
import CmsApi from './../../../service/CmsApi';
import { useUser } from '@clerk/clerk-react'
import {
    Loader2Icon,
    Activity,
} from "lucide-react"
import { Button } from "@/components/ui/button"
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "@/components/ui/card"
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from "@/components/ui/alert-dialog"
import { toast } from 'sonner'
import ApplicationTable from './components/ApplicationTable';
import { useSearchParams } from 'react-router-dom';

const JobApplicationPage = () => {
    const [searchParams] = useSearchParams();
    const type = searchParams.get('type') || 'all';
    console.log('type : ' + type);

    const { user } = useUser();
    const [jobApplications, setJobApplications] = useState([]);
    const [openAlert, setOpenAlert] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedJob, setSelectedJob] = useState({ documentId: null, jobTitle: '', company: '' });

    const [jobApplicationCount, setJobApplicationCount] = useState(0);
    const [jobApplicationPendingCount, setJobApplicationPendingCount] = useState(0);
    const [jobApplicationThisWeekCount, setJobApplicationThisWeekCount] = useState(0);

    useEffect(() => {
        user && GetJobApplicationList()
    }, [user])

    const GetJobApplicationList = () => {
        CmsApi.jobApplication.getJobApplicationsByType(user?.primaryEmailAddress?.emailAddress, type)
            .then(resp => {
                console.log(resp.data.data)
                if (resp.data.data) {
                    const bufferJobApplications = resp.data.data;
                    const pendingCount = bufferJobApplications.filter(app => app.state === "pending").length;
                    const nonPendingCount = bufferJobApplications.filter(app => app.state !== "pending").length;

                    const startOfWeek = new Date();
                    startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay()); 
                    const applyThisWeekNonPendingCount = bufferJobApplications.filter(app => {
                        const applyDate = new Date(app.applyDate);
                        return app.state !== "pending" && applyDate >= startOfWeek;
                    }).length;

                    setJobApplications(bufferJobApplications);
                    setJobApplicationPendingCount(pendingCount);
                    setJobApplicationCount(nonPendingCount);
                    setJobApplicationThisWeekCount(applyThisWeekNonPendingCount);
                }

            })
    }

    const onDelete = () => {
        setLoading(true);
        CmsApi.jobApplication.deleteJobApplication(selectedJob.documentId).then(resp => {
            console.log(resp);
            toast('Job Application Deleted!');
            GetJobApplicationList()
            setLoading(false);
            setOpenAlert(false);
        }, (error) => {
            setLoading(false);
        })
    }

    const handleDeleteClick = (job) => {
        setSelectedJob({ documentId: job.documentId, jobTitle: job.jobTitle, company: job.company });
        setOpenAlert(true);
    }

    const handleStatusChange = (documentId, newState) => {
        const data = {
            data: {
                state: newState
            }
        }
        CmsApi.jobApplication.updateJobApplication(documentId, data)
            .then(resp => {
                toast('Job Application State Updated!');
                GetJobApplicationList();
            })
            .catch(error => {
                toast('Failed to update job application.');
            });
    };

    return (
        <div>

            <div className="flex min-h-screen w-full flex-col bg-muted/40">



                <div className="flex flex-col sm:gap-4 sm:py-4 sm:pl-14">
                    <div className="grid gap-4 p-4 md:grid-cols-2 md:gap-8 lg:grid-cols-4">
                        <Card x-chunk="dashboard-01-chunk-0">
                            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                                <CardTitle className="text-sm font-medium">
                                    Job Application
                                </CardTitle>
                                <Activity className="h-4 w-4 text-muted-foreground" />
                            </CardHeader>
                            <CardContent>
                                <div className="text-2xl font-bold flex">
                                    <div>All:{jobApplicationCount} | This Week : {jobApplicationThisWeekCount} | Pending : {jobApplicationPendingCount}</div>
                                </div>
                                <p className="text-xs text-muted-foreground flex">
                                {type === 'last' ? 'last 30 days closed job applications... ...' : 'in process job applications... ...'}
                                </p>
                            </CardContent>
                        </Card>


                    </div>
                    <div className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
                        <ApplicationTable
                            jobApplications={jobApplications}
                            handleStatusChange={handleStatusChange}
                            handleDeleteClick={handleDeleteClick} />
                        <AlertDialog open={openAlert}>

                            <AlertDialogContent>
                                <AlertDialogHeader>
                                    <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                                    <AlertDialogDescription>
                                        This action cannot be undone. This will permanently delete the job application for {selectedJob.jobTitle} at {selectedJob.company} and remove your data from our servers.
                                    </AlertDialogDescription>
                                </AlertDialogHeader>
                                <AlertDialogFooter>
                                    <AlertDialogCancel onClick={() => setOpenAlert(false)}>Cancel</AlertDialogCancel>
                                    <AlertDialogAction className="bg-red-500 hover:bg-red-700 text-white" onClick={onDelete}
                                        disabled={loading}>
                                        {loading ? <Loader2Icon className='animate-spin' /> : 'Delete'}
                                    </AlertDialogAction>
                                </AlertDialogFooter>
                            </AlertDialogContent>
                        </AlertDialog>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default JobApplicationPage