import axios from 'axios';

const API_KEY = import.meta.env.VITE_STRAPI_API_KEY;
const axiosClient = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL + "/api/",
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${API_KEY}`
  }
});

const createJobApplication = (data) => axiosClient.post('/job-applications', data);

const getJobApplications = (userEmail) => axiosClient.get('/job-applications?filters[userEmail][$eq]=' + userEmail);

const getJobApplicationsByType = async (userEmail, type) => {
  try {
    if (type == 'last') {
      const date30DaysAgo = new Date();
      date30DaysAgo.setDate(date30DaysAgo.getDate() - 30);
      const date30DaysAgoISO = date30DaysAgo.toISOString();


      return axiosClient.get(`/job-applications?filters[userEmail][$eq]=${userEmail}&filters[state][$in]=closed&filters[updatedAt][$gte]=${date30DaysAgoISO}&sort[0]=updatedAt:desc&populate=*`);
    }
    return axiosClient.get(`/job-applications?filters[userEmail][$eq]=${userEmail}&filters[$and][0][state][$ne]=success&filters[$and][1][state][$ne]=closed&filters[$and][2][state][$ne]=cancel&sort[0]=updatedAt:desc&populate=*`);
  } catch (error) {
    console.error('Error fetching process job applications count:', error);
    throw error;
  }
};

const getProcessJobApplications = (userEmail) => axiosClient.get(`/job-applications?filters[userEmail][$eq]=${userEmail}&filters[$and][0][state][$ne]=success&filters[$and][1][state][$ne]=closed&filters[$and][2][state][$ne]=cancel&sort[0]=updatedAt:desc&populate=*`);

const getProcessJobApplicationsCount = async (userEmail) => {
  try {
    const response = await axiosClient.get(`/job-applications?filters[userEmail][$eq]=${userEmail}&filters[$and][0][state][$ne]=success&filters[$and][1][state][$ne]=closed&filters[$and][2][state][$ne]=cancel`);
    return response.data.data.length;
  } catch (error) {
    console.error('Error fetching process job applications count:', error);
    throw error;
  }
};
// const getClosedJobApplications = (userEmail) =>  axiosClient.get(`/job-applications?filters[userEmail][$eq]=${userEmail}&filters[state][$in]=success,closed,cancel&sort[0]=updatedAt:desc`);

const updateJobApplication = (id, data) => axiosClient.put('/job-applications/' + id, data);

const getJobApplicationById = (id) => axiosClient.get('/job-applications/' + id + "?populate=*");

const deleteJobApplication = (id) => axiosClient.delete('/job-applications/' + id);

const JOB_STATES = [
  'pending',
  'apply',
  'viewed',
  'round1',
  'round2',
  'round3',
  'round4',
  'success',
  'closed',
  'cancel'
];

const JOB_SOURCES = [
  'default',
  'seek',
  'linkedin',
  'trademe',
  'friends',
  'social media'
];

const JOB_PRIORITY = [
  'low',
  'medium',
  'high'
];

export default {
  createJobApplication,
  getJobApplications,
  getJobApplicationsByType,
  getProcessJobApplications,
  getProcessJobApplicationsCount,
  // getClosedJobApplications,
  updateJobApplication,
  getJobApplicationById,
  deleteJobApplication,
  JOB_STATES,
  JOB_SOURCES,
  JOB_PRIORITY
};
